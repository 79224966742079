.WizardDisclosure {
    color: white;

    &-rules {
        h4 {
            font-size: 1rem;
        }

        p {
            color: white;
            font-weight: 400;
        }

    }

    &-actions{
      display: flex;
      justify-content: space-around;

      div {
          margin: 5px;
          padding: 5px;
          background-color: white;
          color:  black;
          border: 1px solid white;
          border-radius: 2px;
      }
    }
}