.FileUploadForm {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-uploadBtn {
      background: #6A1B9A;
      padding: 0.5rem;
      border-radius: 8px;
    }

    &-submitBtn {
        padding: 0.5rem;
        width: 100%;
        background-color: white;
        font-size: 1rem;
    }

    &-text {
        font-family: 'Open Sans', sans-serif;
        color: white;
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }
}