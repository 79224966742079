.UploadWizard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3rem;
    padding: 1rem;
    max-width: 100rem;
    // border: 3px solid orange;
    border-radius: 5px;

    &_fileUploadForm {
       text-align: center;
       color: white;
       padding: 0.5rem;
       border-radius: 8px;

       &-field {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1; 
       }
    }
}